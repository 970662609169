import { frontendURL } from 'dashboard/helper/URLHelper';

const SettingsContent = () => import('../Wrapper.vue');
const Mvp = () => import('./Index.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/mvp'),
      component: SettingsContent,
      props: () => {
        return {
          headerTitle: 'INSURMAGIC.MVP.HEADER',
          headerButtonText: 'INSURMAGIC.MVP.HEADER_BTN_TXT',
          icon: 'automation',
          showBackButton: false,
          showNewButton: false,
        };
      },
      children: [
        {
          path: '',
          name: 'mvp_wrapper',
          component: Mvp,
          meta: {
            permissions: ['administrator'],
          },
        },
      ],
    },
  ],
};
